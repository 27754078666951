import "./App.css";
import { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Card,
  TextField,
  Backdrop,
  CircularProgress,
  Select,
  MenuItem,
  Alert,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { saveAs } from "file-saver";
import DownloadIcon from "@mui/icons-material/Download";
import Header from "./components/Header";
import Result from "./components/Result";
import Login from "./components/Login";
import HistoryDrawer from "./components/History";
import Usage from "./components/Usage";
import axios from "axios";
import { countChineseCharacters, displayDate } from "./components/action";

function App() {
  const year = new Date().getFullYear();
  const [token, setToken] = useState(null);
  const [uid, setUid] = useState(null);
  const [name, setName] = useState(null);
  const [topic, setTopic] = useState("");
  const [text, setText] = useState("");
  const [teacher, setTeacher] = useState(true);
  const [result, setResult] = useState(null);
  const [model, setModel] = useState("gpt4");
  const [markingScheme, setMarkingScheme] = useState("TSA");
  const [genreList, setGenreList] = useState([]);
  //const [grade, setGrade] = useState(1);
  const [wordCounts, setWordCounts] = useState(0);
  const [genre, setGenre] = useState("");
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("teacher");
  const [developer, setDeveloper] = useState(false);
  const [error, setError] = useState(null);
  const [history, setHistory] = useState(null);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [usage, setUsage] = useState(null);
  const [llmList, setLLMList] = useState([]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(null);
    const data = new FormData(event.currentTarget);

    await fetch(`${process.env.REACT_APP_API_HOST}/v2/user/auth`, {
      method: "POST",
      headers: {
        username: data.get("username"),
        password: data.get("password"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error > 0) {
          setError("登入名稱或密碼不正確");
        } else if (data.role === "student") {
          setError(
            "同學們！這個平台是專為老師而設的教學輔助工具。學生帳戶暫時未能登入。"
          );
        } else {
          setToken(data.token);
          setUid(data.userId);
          setName(data.name);
          setRole(data.role);
          console.log(data.role);
          if (data.role === "developer") {
            setDeveloper(true);
          }
        }
      })
      .catch((err) => {
        console.error(err.message);
        setError(err.message);
      });
  };

  const handleLogout = (event) => {
    setToken(null);
    setModel("gpt4");
    setUid(null);
    setName(null);
  };

  const handleInput = (event) => {
    setText(event.target.value);
  };

  const handleSwitch = (event) => {
    setTeacher(!event.target.checked);
  };

  const handleSwitchModel = (event) => {
    setModel(event.target.checked ? "gpt4" : "ChatGPT");
  };

  const handleMarkingSchemeChange = (event) => {
    setMarkingScheme(event.target.value);
  };

  const handleGenreChange = (event) => {
    setGenre(event.target.value);
  };
  useEffect(() => {
    const getLLMList = async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/v2/writing/llm`,
        {},
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      console.log("getLLMList", response);
      if (response?.data) {
        setLLMList(response.data);
      }
    };
    if (token !== null) {
      getLLMList();
    }
  }, [token]);

  useEffect(() => {
    const getGenreList = async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/v2/writing/genre`,
        {
          //type: "genre",
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      //console.log(response);
      if (response.data.success) setGenreList(response.data.result);
    };

    if (token !== null) {
      getGenreList();
    }
  }, [token]);

  const genFlowSuggestion = async (llm) => {
    setLoading(true);
    setError(null);
    const response = await axios
      .post(
        `${process.env.REACT_APP_API_HOST}/v2/writing/mind`,
        {
          input: topic.trim(),
          idea: text.trim(),
          genre: genre,
          marking_scheme: markingScheme,
          type: "flowRecommend",
          llm: llm,
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch((err) => {
        console.error(err.message);
        setError(err.message);
        setLoading(false);
      });
    console.log("getFlowSuggestion", response);
    if (response?.data.error > 0) {
      setError(response.data.message);
    } else {
      setResult(response?.data);
      await getUsage();
    }
    setLoading(false);

    // await fetch(`${process.env.REACT_APP_API_HOST}/v2/writing/mind`, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     input: topic.trim(),
    //     idea: text.trim(),
    //     marking_scheme: markingScheme,
    //     type: 'recommend',
    //   }),
    //   headers: {
    //     "Content-type": "application/json; charset=UTF-8",
    //     authorization: `Bearer ${token}`,
    //   },
    // })
    //   .then((response) => response.json())
    //   .then(async (data) => {
    //     if (data.error > 0) {
    //       setError(data.message);
    //     } else {
    //       setResult(data);
    //       await getUsage();
    //     }
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.error(err.message);
    //     setError(err.message);
    //     setLoading(false);
    //   });
  };

  const genFlowGen = async (llm) => {
    setLoading(true);
    setError(null);
    await fetch(`${process.env.REACT_APP_API_HOST}/v2/writing/mind`, {
      method: "POST",
      body: JSON.stringify({
        input: topic.trim(),
        idea: text.trim(),
        marking_scheme: markingScheme,
        type: "flowGenerate",
        llm: llm,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.error > 0) {
          setError(data.message);
        } else {
          setResult(data);
          await getUsage();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err.message);
        setError(err.message);
        setLoading(false);
      });
  };

  const mark = async (llm) => {
    setLoading(true);
    setError(null);
    // await fetch(
    //   `${
    //     process.env.REACT_APP_API_HOST
    //   }/v2/writing/mark?ts=${new Date().getTime()}`,
    //   {
    //     method: "POST",
    //     body: JSON.stringify({
    //       writing: text.trim(),
    //       role:
    //         role === "student"
    //           ? "student"
    //           : teacher
    //           ? "teacher"
    //           : developer
    //           ? "developer"
    //           : "student",
    //       userId: uid,
    //       model: model,
    //       grade: grade,
    //       genre: genre,
    //     }),
    //     headers: {
    //       "Content-type": "application/json; charset=UTF-8",
    //       authorization: `Bearer ${token}`,
    //     },
    //   }
    // )
    //   .then((response) => response.json())
    //   .then(async (data) => {
    //     if (data.error > 0) {
    //       setError(data.message);
    //     } else {
    //       setResult(data);
    //       await getUsage();
    //     }
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.error(err.message);
    //     setError(err.message);
    //     setLoading(false);
    //   });
    // const response = await axios.post(
    //   `${process.env.REACT_APP_API_HOST}/v2/writing/mind`,
    //   {
    //     type: "flowMark",
    //     input: text.trim(),
    //     topic: topic.trim,
    //     marking_scheme: markingScheme,
    //   },
    //   {
    //     headers: { Authorization: "Bearer " + token },
    //   }
    // );
    await fetch(`${process.env.REACT_APP_API_HOST}/v2/writing/mind`, {
      method: "POST",
      body: JSON.stringify({
        topic: topic.trim(),
        input: text.trim(),
        marking_scheme: markingScheme,
        type: "flowMark",
        llm: llm,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.error > 0) {
          setError(data.message);
        } else {
          setResult(data);
          await getUsage();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err.message);
        setError(err.message);
        setLoading(false);
      });
  };

  //   console.log('mark', response);
  //   if (response.data.error > 0) {
  //     setError(response.data.message);
  //   } else {
  //     //setResult(response.data);
  //     await getUsage();
  //   }
  //   setLoading(false);
  // };

  const viewHistory = async () => {
    await fetch(`${process.env.REACT_APP_API_HOST}/v2/writing/history`, {
      method: "POST",
      body: JSON.stringify({
        page: 1,
        limit: 12,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error > 0) {
          setError(data.message);
        } else {
          setHistory(data.history);
          setHistoryOpen(true);
        }
      })
      .catch((err) => {
        console.error(err.message);
        setError(err.message);
      });
  };

  const selectResult = (resultData) => {
    if (resultData) {
      setTopic(resultData.topic);
      resultData?.settings.genre
        ? setGenre(resultData?.settings.genre)
        : setGenre("");
      setMarkingScheme(resultData.marking_scheme);
      resultData?.wordCount
        ? setWordCounts(resultData.wordCount)
        : setWordCounts(-1);
      resultData?.idea ? setText(resultData?.idea) : setText(resultData.input);
      resultData.result = resultData.output;
      setResult(resultData);
      setHistoryOpen(false);
    }
  };

  const handleTopicInput = (event) => {
    setTopic(event.target.value);
  };

  const getUsage = async () => {
    await fetch(`${process.env.REACT_APP_API_HOST}/v2/writing/getUsage`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error > 0) {
          setError(data.message);
        } else {
          setUsage(data);
        }
      })
      .catch((err) => {
        console.error(err.message);
        setError(err.message);
      });
  };

  // const downloadText = async () => {
  //   if (!result || (!result.id && !result._id)) {
  //     return null;
  //   }
  //   await fetch(
  //     `${
  //       process.env.REACT_APP_API_HOST
  //     }/v2/writing/text?ts=${new Date().getTime()}`,
  //     {
  //       method: "POST",
  //       body: JSON.stringify({
  //         id: result.id ?? result._id,
  //       }),
  //       headers: {
  //         "Content-type": "application/json; charset=UTF-8",
  //         authorization: `Bearer ${token}`,
  //       },
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const blob = new Blob([data.text], {
  //         type: "text/plain;charset=utf-8",
  //       });
  //       const filename =
  //         data.type === "mark"
  //           ? "評改作文"
  //           : data.role === "teacher"
  //           ? "生成範文"
  //           : "作文建議";
  //       saveAs(blob, filename);
  //     })
  //     .catch((err) => {
  //       console.error(err.message);
  //       setError(err.message);
  //     });
  // };

  const DecodeText = () => {
    let blobText;
    if (result.result && typeof result.result === "object") {
      blobText = name + " 作文 " + "\n";
      if (result._createdAt) {
        blobText += "(" + displayDate(result._createdAt) + ")\n";
      }
      blobText += "\n";
      blobText += topic + "\n";
      if (result.input) {
        blobText += result.input + "\n";
      } else {
        blobText += text;
      }
      if (result.result) {
        if (result?.marking_scheme) {
          if (
            result.marking_scheme === "DSE" ||
            result.marking_scheme === "IGCSE"
          ) {
            blobText += `\n\n老師評語(${result.marking_scheme}(測試版)): \n`;
          } else {
            blobText += `\n\n老師評語(${result.marking_scheme}): \n`;
          }
        }
        const recordGrade = result.result;

        if (
          result?.marking_scheme !== "IGCSE" ||
          !recordGrade.hasOwnProperty("totalScore")
        ) {
          recordGrade.grade.forEach((item) => {
            blobText += `【${item.id}】${item.item}\n`;
            blobText += `等級：${item.grade}\n`;
            // result?.marking_scheme === "Pre-S1"
            //   ? (blobText += `分數：${item.score}\n`)
            //   : "";
            if (result?.marking_scheme === "Pre-S1") {
              blobText += `分數：${item?.score}\n`;
            }
            if (item.pros) blobText += `應繼續保持的地方：\n${item.pros}\n`;
            if (item.cons) blobText += `應改進的地方：\n${item.cons}\n`;
            if (item.comment) blobText += `評語：\n${item.comment}\n`;
            blobText += "\n";
          });

          blobText += "整體評核：\n";
          blobText += `等級： ${recordGrade.overall.grade}\n`;
          blobText += `評語： \n${recordGrade.overall.comment}\n\n`;

          blobText += "改進建議:\n";
          blobText += `評語：\n${recordGrade.suggestion.comment}\n\n`;

          if (result?.vocab) {
            blobText += `【《香港小學學習字詞表》第一學習階段字詞】（${result.vocab?.stage1.length}個）:\n`;
            blobText += result.vocab?.stage1.join(", ") + "\n\n";

            blobText += `【《香港小學學習字詞表》第二學習階段字詞】（${result.vocab?.stage2.length}個）:\n`;
            blobText += result.vocab?.stage2.join(", ") + "\n\n";
          }
        } else if (
          result?.marking_scheme === "IGCSE" ||
          recordGrade.hasOwnProperty("totalScore")
        ) {
          recordGrade.grade.forEach((item) => {
            blobText += `【${item.id}】${item.item}\n`;
            item.contents.forEach((content, index) => {
              blobText += `【${item.id}.${index + 1}】${content.item}\n`;
              if (content.score) {
                blobText += ` Score/分數: ${content.score}\n`;
              }
              blobText += ` 应继续保持的地方: \n${content.pros.chinese}\n`;
              blobText += ` 应改进的地方: \n${content.cons.chinese}\n`;
              blobText += ` Areas to Maintain: \n${content.pros.english}\n`;
              blobText += ` Areas for Improvement: \n${content.cons.english}\n\n`;
            });
            blobText += "\n";
          });
          if (recordGrade?.totalScore) {
            blobText += `Total Score/总分: ${recordGrade.totalScore}\n\n`;
          }
          blobText += "【Recommendations 建议】\n";
          blobText += `建议 (Chinese): \n${recordGrade.suggestion.comment.chinese}\n`;
          blobText += `Suggestions (English): \n${recordGrade.suggestion.comment.english}\n`;
        }
      }

      let filename = name + " - " + "作文 " + topic + " 評改作文(老師版)";

      if (result?._createdAt) {
        filename = filename + " (" + displayDate(result?._createdAt) + ")";
      }

      console.log("blobText", blobText);
      const blob = new Blob([blobText], { type: "text/plain;charset=utf-8" });
      saveAs(blob, filename);
    } else {
      blobText = result.result;

      console.log("blobText", blobText);
      const blob = new Blob([blobText], { type: "text/plain;charset=utf-8" });
      let filename = name + " - " + "作文 " + topic + " 範文/建議";
      saveAs(blob, filename);
    }
  };

  useEffect(() => {
    if (token) {
      const onLoad = async () => {
        await getUsage();
      };
      onLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (text) {
      setWordCounts(countChineseCharacters(text.trim()));
    }
  }, [text]);

  if (!token || !uid) {
    return <Login loginFn={handleLogin} message={error} />;
  }

  return (
    <>
      <Header
        username={name}
        logoutFn={handleLogout}
        genFn={genFlowGen}
        recommendFn={genFlowSuggestion}
        llmList={llmList}
        markFn={mark}
        //genText={teacher ? "生成範文" : "作文建議"}
        historyFn={viewHistory}
        //switchModelFn={handleSwitchModel}
        model={model}
        role={role}
        teacher={teacher}
        switchRoleFn={handleSwitch}
        loading={loading}
      />
      <Container>
        <Grid container sx={{ mt: 8, pt: 3 }}>
          {error && (
            <Alert severity="error" sx={{ width: "100%", my: 3 }}>
              {error}
            </Alert>
          )}
          <Grid item xs={12} md={3}>
            {/* <Select
              id="grade"
              value={grade}
              label='小學年級' onChange={handleGradeChange}
              sx={{ width: "100%" }}
            >
              <MenuItem value={1}>小學一年級</MenuItem>
              <MenuItem value={2}>小學二年級</MenuItem>
              <MenuItem value={3}>小學三年級</MenuItem>
              <MenuItem value={4}>小學四年級</MenuItem>
              <MenuItem value={5}>小學五年級</MenuItem>
              <MenuItem value={6}>小學六年級</MenuItem>
            </Select> */}
            <Select
              id="markingScheme"
              value={markingScheme}
              onChange={handleMarkingSchemeChange}
              sx={{ width: "100%" }}
            >
              <MenuItem value={"TSA"}>TSA</MenuItem>
              <MenuItem value={"Pre-S1"}>Pre-S1</MenuItem>
              <MenuItem value={"DSE"}>DSE</MenuItem>
              <MenuItem value={"IGCSE"}>IGCSE</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={3}>
            <Select
              id="genre"
              value={genre}
              /* label='文體'  */ onChange={handleGenreChange}
              sx={{ width: "100%" }}
              displayEmpty
            >
              <MenuItem value="">
                <em>不指定文體</em>
              </MenuItem>
              {genreList?.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.name}
                  disabled={option.disabled}
                >
                  {option.name}
                </MenuItem>
              ))}
              {/* <MenuItem value={"描寫文"}>描寫文</MenuItem>
              <MenuItem value={"說明文"}>說明文</MenuItem>
              <MenuItem value={"記敍文"}>記敍文</MenuItem>
              <MenuItem value={"抒情文"}>抒情文</MenuItem>
              <MenuItem value={"議論文"}>議論文</MenuItem> */}
              {/* <MenuItem value={'書信'}>實用文 - 書信</MenuItem> */}
            </Select>
          </Grid>
          <Grid item xs={12} md={5} sx={{ alignSelf: "center" }}>
            <Usage data={usage} developer={developer} />
          </Grid>
          <Grid item xs={12} md={1} sx={{ alignSelf: "center" }}>
            <Button
              aria-label="下載"
              size="large"
              color="primary"
              variant="contained"
              disabled={result === null}
              onClick={DecodeText}
              fullWidth
            >
              <DownloadIcon />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ height: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  //alignItems: "stretch",
                }}
              >
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{ width: "100%", height: "100%" }}
                >
                  <TextField
                    multiline
                    fullWidth
                    //sx={{ width: "100%", height: "100%", display: "flex" }}
                    rows={2}
                    placeholder="請輸入作文題目"
                    label="作文題目"
                    value={topic}
                    onChange={handleTopicInput}
                    variant="filled"
                  />
                  <TextField
                    multiline
                    fullWidth
                    //sx={{ width: "100%", height: "100%", display: "flex" }}
                    rows={18}
                    placeholder="輸入整篇文章以進行評改 或 內容大意以生成範文/建議"
                    label="整篇文章 或 內容大意 (可選填)"
                    value={text}
                    onChange={handleInput}
                    variant="filled"
                  />
                  {wordCounts >= 0 ? (
                    <Typography
                      variant="body2"
                      sx={{ color: "#707070", ml: 2 }}
                    >
                      字數: {wordCounts}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Stack>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                height: "100%",
                whiteSpace: "pre-wrap",
                bgcolor: grey[100],
                p: 2,
                boxSizing: "border-box",
              }}
            >
              <Result data={result} />
            </Card>
          </Grid>
        </Grid>

        <HistoryDrawer
          historyData={history}
          state={historyOpen}
          setDrawer={setHistoryOpen}
          resultFn={selectResult}
        />

        {/* <Box sx={{ mt: 2, textAlign: 'center' }}>
        <ButtonGroup variant='contained' size='large'>
          <Button onClick={gen}>
            <Typography variant='h4'>{teacher ? '生成範文' : '作文建議'}</Typography>
          </Button>
          <Button onClick={mark} color='secondary'>
            <Typography variant='h4'>評改作文</Typography>
          </Button>
        </ButtonGroup>
      </Box> */}

        <Box sx={{ mt: 3 }}>&copy; m-Chinese Solution Limited {year}</Box>
        <Box sx={{ textAlign: "right" }}>
          <img src="/logo_128.png" alt="mLang" />
        </Box>

        <Backdrop sx={{ color: "#fff", zIndex: 999 }} open={loading}>
          <CircularProgress color="warning" />
          <Typography variant="h6" color="warning" sx={{ ml: 2 }}>
            你的要求已提交mAI mind，生成結果需時一至兩分鐘，請耐心等候。
          </Typography>
        </Backdrop>
      </Container>
    </>
  );
}

export default App;
