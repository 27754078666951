import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DisplayComment from "./DisplayComment";

// result: resultText, vocab: updateResultRecord.vocab, variants: updateResultRecord.variants, wordCount: updateResultRecord.wordCount
export default function Result({ data }) {
  //console.log("Result", data, data?.settings.mode);
  if (!data) {
    data = {
      result: null,
      grade: { grade: [], overall: {}, suggestion: {} },
      vocab: { stage1: [], stage2: [] },
      variants: {},
      hans: {},
    };
  }

  if (data !== null && !data.grade) {
    return (
      <div>
        <Typography variant="h6">{data.result}</Typography>
        {data?.llm && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            LLM: {data.llm}
          </Typography>
        )}
      </div>
    );
  }

  const gradeResult = data.grade;
  const marking = data?.marking_scheme ? data.marking_scheme : "";
  // Check Parse error result
  // if (gradeResult.grade) {
  //   for (let i = 0; i < gradeResult.grade.length; i++) {
  //     if (
  //       gradeResult?.grade[i].comment === "）" ||
  //       gradeResult?.grade[i].grade.includes("（")
  //     ) {
  //       return <Typography variant="h6">{data.result}</Typography>;
  //     }
  //   }
  // }

  /*let gptItems = [];
  if (gptResult) {
    const parts = gptResult.replace(/^評審建議$\r?\n/,'').split(/\r?\n\r?\n+/);
    for (let i = 0; i < parts.length; i++) {
      const firstLine = parts[i].split(/\r?\n/)[0];
      gptItems.push([firstLine, parts[i].replace(firstLine, '')]);
    }
  } */
  const stage1Vocab = data.vocab?.stage1.length ?? 0;
  const stage2Vocab = data.vocab?.stage2.length ?? 0;
  const variants = Object.keys(data.variants ?? {}).length;
  let variantsText = "";
  if (variants > 0) {
    let firstVariant = true;
    for (const [variant, normal] of Object.entries(data.variants)) {
      if (firstVariant) {
        firstVariant = false;
      } else {
        variantsText += `、`;
      }
      variantsText += `${variant} （${normal}）`;
    }
  }
  const hans = Object.keys(data.hans ?? {}).length;
  let hansText = "";
  if (hans > 0) {
    let firstHans = true;
    for (const [hans, hant] of Object.entries(data.hans)) {
      if (firstHans) {
        firstHans = false;
      } else {
        hansText += `、`;
      }
      hansText += `${hans} （${hant}）`;
    }
  }
  const gradeTitle = [
    "【1】內容 （最高五等）",
    "【2】結構 （最高五等）",
    "【3】文句 （最高四等）",
    "【4】詞語運用 （最高四等）",
    "【5】錯別字 （最高三等）",
    "【6】標點符號 （最高三等）",
  ];

  return (
    <div>
      {gradeResult.grade && gradeResult.grade.length > 0 ? (
        <DisplayComment gradeResult={gradeResult} mode={marking} />
      ) : (
        // ? gradeResult.grade.map((item, idx) => {
        //     return (
        //       // <Accordion key={`grade-${idx}`}>
        //       //   <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        //       //     <Typography>
        //       //       【{item.id}】 {item.item} （{item.grade}等）
        //       //     </Typography>
        //       //   </AccordionSummary>
        //       //   <AccordionDetails>
        //       //     <Typography>{item.comment}</Typography>
        //       //   </AccordionDetails>
        //       // </Accordion>
        //     );
        //  })
        gradeTitle.map((item, idx) => {
          return (
            <Accordion disabled key={`grade-${idx}`}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{item}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography></Typography>
              </AccordionDetails>
            </Accordion>
          );
        })
      )}

      {!data ? (
        <Accordion disabled={Object.keys(gradeResult.overall ?? {}).length < 1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              整體評核 （{gradeResult.overall?.grade ?? "最高五"}等）
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{gradeResult.overall?.comment ?? ""}</Typography>
          </AccordionDetails>
        </Accordion>
      ) : null}
      {!data ? (
        <Accordion
          disabled={Object.keys(gradeResult.suggestion ?? {}).length < 1}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>改進建議</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{gradeResult.suggestion?.suggestions ?? ""}</Typography>
          </AccordionDetails>
        </Accordion>
      ) : null}

      <Accordion disabled={stage1Vocab < 1}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            《香港小學學習字詞表》第一學習階段字詞
            {stage1Vocab > 0 ? `（${stage1Vocab}個）` : ""}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {stage1Vocab > 0 ? data.vocab.stage1.join("、") : ""}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion disabled={stage2Vocab < 1}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            《香港小學學習字詞表》第二學習階段字詞
            {stage2Vocab > 0 ? `（${stage2Vocab}個）` : ""}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {stage2Vocab > 0 ? data.vocab.stage2.join("、") : ""}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion disabled={variants < 1}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            異體字{variants > 0 ? `（${variants}個）` : ""}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{variantsText}</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion disabled={hans < 1}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>簡體字{hans > 0 ? `（${hans}個）` : ""}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{hansText}</Typography>
        </AccordionDetails>
      </Accordion>
      {data?.llm && (
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          LLM: {data.llm}
        </Typography>
      )}
    </div>
  );
}
