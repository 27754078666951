import { useState } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Stack,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";

const drawerWidth = 240;
const functionList = ["生成範文", "作文建議", "評改作文"];

function Header(props) {
  const theme = useTheme();

  const [genddList, setgenDDList] = useState(false);
  const [recddList, setrecDDList] = useState(false);
  const [markddList, setmarkDDList] = useState(false);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        mLang AI中文改文
      </Typography>
      <Divider />
      <List>
        <ListItem
          disablePadding
          sx={{
            background: theme.palette.info.main,
            color: theme.palette.info.contrastText,
          }}
        >
          <ListItemButton
            disabled={props.loading}
            sx={{ textAlign: "center" }}
            onClick={() => {
              props.genFn();
              handleDrawerToggle();
            }}
          >
            <ListItemText primary={"生成範文"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            background: theme.palette.info.main,
            color: theme.palette.info.contrastText,
          }}
        >
          <ListItemButton
            disabled={props.loading}
            sx={{ textAlign: "center" }}
            onClick={() => {
              props.recommendFn();
              handleDrawerToggle();
            }}
          >
            <ListItemText primary={"作文建議"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          }}
        >
          <ListItemButton
            disabled={props.loading}
            sx={{ textAlign: "center" }}
            onClick={() => {
              props.markFn();
              handleDrawerToggle();
            }}
          >
            <ListItemText primary={"評改作文"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            background: theme.palette.error.main,
            color: theme.palette.error.contrastText,
          }}
        >
          <ListItemButton
            disabled={props.loading}
            sx={{ textAlign: "center" }}
            onClick={() => {
              props.historyFn();
              handleDrawerToggle();
            }}
          >
            <ListItemText primary={"紀錄"} />
          </ListItemButton>
        </ListItem>
        {/* 
        <ListItem disablePadding>
          <ListItemText sx={{ textAlign: "center" }}>AI</ListItemText>
          <Switch
            checked={props.model === "gpt4"}
            color="secondary"
            onChange={props.switchModelFn}
            disabled={props.role !== "developer"}
          />
        </ListItem> */}

        {props.role !== "student" && (
          <ListItem disablePadding>
            <ListItemText sx={{ textAlign: "center" }}>學生模式</ListItemText>
            <Switch
              color="info"
              checked={!props.teacher}
              onChange={props.switchRoleFn}
            />
          </ListItem>
        )}

        <ListItem disablePadding onClick={(e) => e.preventDefault()}>
          <ListItemText sx={{ textAlign: "center" }}>
            {props.username}
          </ListItemText>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => {
              props.logoutFn();
              handleDrawerToggle();
            }}
          >
            <ListItemText primary={"登出"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="打開導航欄"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: "block" }}
          >
            mAI Mind 個人版
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
            <Stack direction={"row"} spacing={1}>
              {/* <Button
                size="large"
                color="info"
                variant="contained"
                onClick={props.genFn}
                sx={{ color: "#fff", mx: 2 }}
              >
                生成範文
              </Button>
              <Button
                size="large"
                color="info"
                variant="contained"
                onClick={props.recommendFn}
                sx={{ color: "#fff", mx: 2 }}
              >
                作文建議
              </Button> */}
              {/* <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={props.markFn}
                sx={{ color: "#fff", mx: 2 }}
              >
              評改作文
              </Button> */}
              <Box sx={{ position: "relative" }}>
                <Button
                  size="large"
                  variant="contained"
                  color="info"
                  //sx={{ borderRadius: "10px" }}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={() => {
                    setgenDDList((curr) => !curr);
                    setrecDDList(false);
                    setmarkDDList(false);
                  }}
                >
                  生成範文
                </Button>
                {genddList !== false && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 2,
                      p: 2,
                      backgroundColor: "white",
                      borderRadius: 3,
                    }}
                  >
                    <Stack direction={"column"} spacing={1}>
                      {props.llmList.map((item, i) => {
                        return (
                          <Button
                            key={i}
                            variant="contained"
                            sx={{ borderRadius: "10px" }}
                            onClick={() => {
                              setgenDDList(false);
                              setrecDDList(false);
                              setmarkDDList(false);
                              props.genFn(item.value);
                            }}
                          >
                            {item.label}
                          </Button>
                        );
                      })}
                    </Stack>
                  </Box>
                )}
              </Box>
              <Box sx={{ position: "relative" }}>
                <Button
                  size="large"
                  variant="contained"
                  color="info"
                  //sx={{ borderRadius: "10px" }}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={() => {
                    setgenDDList(false);
                    setrecDDList((curr) => !curr);
                    setmarkDDList(false);
                  }}
                >
                  作文建議
                </Button>
                {recddList && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 2,
                      p: 2,
                      backgroundColor: "white",
                      borderRadius: 3,
                    }}
                  >
                    <Stack direction={"column"} spacing={1}>
                      {props.llmList.map((item, i) => {
                        return (
                          <Button
                            key={i}
                            variant="contained"
                            sx={{ borderRadius: "10px" }}
                            onClick={() => {
                              setgenDDList(false);
                              setrecDDList(false);
                              setmarkDDList(false);
                              props.recommendFn(item.value);
                            }}
                          >
                            {item.label}
                          </Button>
                        );
                      })}
                    </Stack>
                  </Box>
                )}
              </Box>
              <Box sx={{ position: "relative" }}>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  //sx={{ borderRadius: "10px" }}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={() => {
                    setmarkDDList((curr) => !curr);
                    setgenDDList(false);
                    setrecDDList(false);
                  }}
                >
                  評改作文
                </Button>
                {markddList && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 2,
                      p: 2,
                      backgroundColor: "white",
                      borderRadius: 3,
                    }}
                  >
                    <Stack direction={"column"} spacing={1}>
                      {props.llmList.map((item, i) => {
                        return (
                          <Button
                            key={i}
                            variant="contained"
                            sx={{ borderRadius: "10px" }}
                            onClick={() => {
                              setmarkDDList(false);
                              setgenDDList(false);
                              setrecDDList(false);
                              props.markFn(item.value);
                            }}
                          >
                            {item.label}
                          </Button>
                        );
                      })}
                    </Stack>
                  </Box>
                )}
              </Box>
              <Button
                size="large"
                color="error"
                variant="contained"
                onClick={() => {
                  setgenDDList(false);
                  setrecDDList(false);
                  setmarkDDList(false);
                  props.historyFn();
                }}
                sx={{ color: "#fff", mx: 2 }}
              >
                紀錄
              </Button>
            </Stack>
          </Box>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {/* {props.role === "developer" && (
              <FormControlLabel
                control={
                  <Switch
                    checked={props.model === "gpt4"}
                    color="secondary"
                    onChange={props.switchModelFn}
                    //disabled={props.role !== "developer"}
                  />
                }
                label="GPT-4增強"
                sx={{
                  "& .MuiFormControlLabel-label.Mui-disabled": {
                    color: "#ececec",
                  },
                }}
              />
            )} */}

            {/* {props.role !== "student" && (
              <FormControlLabel
                control={
                  <Switch
                    color="info"
                    checked={!props.teacher}
                    onChange={props.switchRoleFn}
                  />
                }
                label="學生"
              />
            )} */}

            <Typography
              component={"div"}
              sx={{ display: "inline-flex", color: "#fff", padding: "6px 8px" }}
            >
              {props.username}
            </Typography>
            <Button
              size="large"
              variant="outlined"
              onClick={props.logoutFn}
              sx={{ color: "#fff" }}
            >
              登出
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default Header;
