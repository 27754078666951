import { saveAs } from "file-saver";

const getMaxScore = (itemId, mode) => {
  if (mode === "Pre-S1") {
    switch (itemId) {
      case 0:
        return 35;
      case 1:
        return 15;
      case 2:
        return 7;
      case 3:
        return 10;
      case 4:
        return 3;
      case 5:
        return 0;
      default:
        return 0;
    }
  } else if (mode === "IGCSE") {
    switch (itemId) {
      case 0:
        return 28;
      case 1:
        return 10;
      case 2:
        return 10;
      case 3:
        return 8;
      case 11:
        return 5;
      case 12:
        return 5;
      case 13:
        return 5;
      case 21:
        return 5;
      case 22:
        return 5;
      case 23:
        return 5;
      case 31:
        return 4;
      case 32:
        return 4;
      default:
        return 0;
    }
  }
  return 0;
};

const getGradeFromScore = (itemId, score) => {
  switch (itemId) {
    case 1:
      if (score >= 1 && score <= 3) return "一";
      if (score >= 4 && score <= 6) return "二";
      if (score >= 7 && score <= 9) return "三";
      if (score >= 10 && score <= 12) return "四";
      if (score >= 13 && score <= 15) return "五";
      break;
    case 2:
      if (score === 1) return "一";
      if (score === 2) return "二";
      if (score === 3) return "三";
      if (score >= 4 && score <= 5) return "四";
      if (score >= 6 && score <= 7) return "五";
      break;
    case 3:
      if (score >= 1 && score <= 2) return "一";
      if (score >= 3 && score <= 4) return "二";
      if (score >= 5 && score <= 6) return "三";
      if (score >= 7 && score <= 8) return "四";
      if (score >= 9 && score <= 10) return "五";
      break;
    case 4:
      if (score === 0) return "零";
      if (score === 1) return "一";
      if (score === 2) return "二";
      if (score === 3) return "三";
      break;
    case 5:
      if (score >= -3 && score <= -2) return "一";
      if (score === -1) return "二";
      if (score === 0) return "三";
      break;
    default:
      return "";
  }
};

const getOverallGrade = (totalScore) => {
  if (totalScore >= 31 && totalScore <= 35) return "五";
  if (totalScore >= 26 && totalScore <= 30) return "四";
  if (totalScore >= 19 && totalScore <= 25) return "三";
  if (totalScore >= 10 && totalScore <= 18) return "二";
  if (totalScore >= 0 && totalScore <= 9) return "一";
};

const displayDate = (date) => {
  const d = new Date(date);
  return `${d.getFullYear()}年${
    d.getMonth() + 1
  }月${d.getDate()}日 ${d.getHours()}:${
    d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()
  }`;
};

function countChineseCharacters(input) {
  // Match English words and numbers
  const englishAndNumbers = input.match(/\b[A-Za-z0-9]+\b/g) || [];
  const wordCount = englishAndNumbers.length;

  // Match Chinese characters and punctuation marks
  const regex = /[\u4e00-\u9fff，。！？、；：‘’“”【】《》]/g;
  const matches = input.match(regex);
  const charCount = matches ? matches.length : 0;

  // Total count is the sum of English words/numbers and character count
  return wordCount + charCount;
}

export {
  getMaxScore,
  getGradeFromScore,
  getOverallGrade,
  displayDate,
  countChineseCharacters
};
