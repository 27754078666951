import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getMaxScore } from "./action";

// result: resultText, vocab: updateResultRecord.vocab, variants: updateResultRecord.variants, wordCount: updateResultRecord.wordCount
export default function DisplayComment(props) {
  const { gradeResult, mode } = props;
  console.log("gradeResult", gradeResult);
  return (
    <div>
      {gradeResult.grade && mode != "IGCSE" ? (
        <>
          {gradeResult.grade?.map((item, index) => {
            return (
              <Accordion
                key={`grade-${index}`}
                //onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    【{item.id}】 {item.item} （{item.grade}等）
                    {mode === "Pre-S1"
                      ? item.id === 5 && item.score != 0
                        ? `（扣 ${item.score} 分）/（扣 3 分）`
                        : item.id === 5 && item.score == 0
                        ? `（不扣分）`
                        : `（${item.score} 分）/ ${getMaxScore(
                            item.id,
                            "Pre-S1"
                          )} 分`
                      : ""}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column">
                    {item.item !== "標點符號" && item.item !== "錯別字" ? (
                      <Grid container direction={"column"}>
                        <Typography variant="h5" sx={{ mb: 1 }}>
                          應繼續保持的地方
                        </Typography>
                        {item.pros}
                        <Typography variant="h5" sx={{ mt: 1 }}>
                          應改進的地方
                        </Typography>
                        {item.cons}
                      </Grid>
                    ) : (
                      <Grid container direction={"column"}>
                        <Typography variant="h5">評語</Typography>
                        {item.comment}
                      </Grid>
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </>
      ) : gradeResult.grade && mode === "IGCSE" ? (
        <>
          {gradeResult.grade?.map((item, index) => {
            return (
              <Accordion key={`grade-${index}`} defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    【{item.id}】 {item.item}{" "}
                    {item.sectionScore || item.sectionScore === 0
                      ? `（${item.sectionScore}分）/ ${getMaxScore(
                          item.id,
                          "IGCSE"
                        )}分`
                      : ""}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column">
                    {item.contents.map((content, index) => {
                      return (
                        <Accordion
                          key={`grade-${index}`}
                          //   expanded={
                          //     expanded === "all" ||
                          //     expanded === `panel${content.id}`
                          //   }
                          //   onChange={handleChange(`panel${content.id}`)}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>
                              【{item.id}.{index + 1}】 {content.item}
                              {content.score || content.score === 0
                                ? `（${content.score}分）/ ${getMaxScore(
                                    content.id,
                                    "IGCSE"
                                  )}分`
                                : ""}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack direction="column">
                              <Grid container direction={"column"}>
                                <Typography variant="h5" sx={{ m: 1 }}>
                                  应继续保持的地方
                                </Typography>
                                {content.pros.chinese}
                                <Typography variant="h5" sx={{ m: 1 }}>
                                  应改进的地方
                                </Typography>
                                {content.cons.chinese}
                                <Typography variant="h5" sx={{ m: 1 }}>
                                  Areas to Maintain
                                </Typography>
                                {content.pros.english}
                                <Typography variant="h5" sx={{ m: 1 }}>
                                  Areas for Improvement
                                </Typography>
                                {content.cons.english}
                              </Grid>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </>
      ) : null}
      {mode === "IGCSE" && gradeResult.totalScore ? (
          <Box
            sx={{
              mt: 1,
              p: 2,
              width: "100%",
              backgroundColor: "#FEFEFE",
            }}
          >
            <Typography>
              {`【總分: (${gradeResult.totalScore}) / 28分 】`}
            </Typography>
          </Box>
        ) : (
          ""
        )}
        {gradeResult?.suggestion && mode !== "IGCSE" ? (
          <Stack sx={{ mt: 1 }}>
            <Accordion
              sx={{ mb: 1, width: "100%", position: "relative" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>【改進建議】</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction={"column"}>
                  <Typography variant="h5" sx={{ m: 1 }}>
                    評語
                  </Typography>
                  {gradeResult.suggestion.comment}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Stack>
        ) : gradeResult?.suggestion && mode === "IGCSE" ? (
          <Stack sx={{ mt: 1 }}>
            <Accordion
              sx={{ mb: 1, width: "100%", position: "relative" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  【{gradeResult.suggestion.item}】
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction={"column"}>
                  <Typography variant="h5" sx={{ m: 1 }}>
                    建议
                  </Typography>
                  {gradeResult.suggestion.comment.chinese}
                  <Typography variant="h5" sx={{ m: 1 }}>
                    Suggestions
                  </Typography>
                  {gradeResult.suggestion.comment.english}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Stack>
        ) : null}
        {gradeResult?.overall && (
          <Stack sx={{ mt: 1 }}>
            <Accordion
              sx={{ mb: 1, width: "100%", position: "relative" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  【整體評核{" "}（{gradeResult.overall?.grade}等）
                  】
                  {mode === "Pre-S1"
                    ? `(總分: ${gradeResult.overall?.score})`
                    : ""}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction={"column"}>
                  <Typography variant="h5" sx={{ m: 1 }}>
                    評語
                  </Typography>
                   {gradeResult.overall.comment}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Stack>
        )}
    </div>
  );
}
