import { Drawer, Card, CardContent, Typography, Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { displayDate } from './action';

// const displayDate = (date) => {
//   const d = new Date(date);
//   return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDay()}日 ${d.getHours()}:${d.getMinutes()}`;
// };

const minifyText = (str, length = 30) => {
  if (str.length > length) {
    return str.substring(0, length) + '...';
  } else {
    return str;
  }
};

const HistoryCard = ({ data, click }) => {
  const mode = data?.settings?.mode;
  let modeTitle = '';
  if(mode === 'marking' || mode === 'mark') {
    modeTitle = '評改作文';
  } else if(mode === 'recommend') {
    modeTitle = '作文建議';
  } else if(mode === 'generate') {
    modeTitle = '生成範文';
  }
  return (
    <Card sx={{ height: '100%', cursor: 'pointer' }} onClick={click}>
      <CardContent>
        <Typography variant='subtitle2' color='text.secondary' gutterBottom>
          {displayDate(data._createdAt)}
        </Typography>
        <Typography variant='body1' component='div'>
          {data?.topic ? minifyText(data?.topic) : minifyText(data?.input)}
        </Typography>
        <Typography variant='subtitle2' >
          {modeTitle}
        </Typography>
      </CardContent>
    </Card>
  );
};

const HistoryDrawer = ({ historyData, state, setDrawer, resultFn }) => {
  const theme = useTheme();

  if (!historyData) {
    return null;
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(open);
  };

  const clickHandler = (data) => {
    resultFn(data);
  };

  return (
    <Drawer anchor='bottom' open={state} onClose={toggleDrawer(false)}>
      <Grid
        container
        spacing={2}
        sx={{ p: 3, background: theme.palette.primary.dark, color: theme.palette.info.contrastText }}
      >
        <Grid item xs={12} sx={{ display: { md: 'none' } }}>
          <Button
            variant='contained'
            size='large'
            onClick={toggleDrawer(false)}
            sx={{
              width: '100%',
              background: theme.palette.common.white,
              color: theme.palette.common.white.contrastText,
            }}
          >
            ❌
          </Button>
        </Grid>
        {historyData &&
          historyData.length > 0 &&
          historyData.map((data, idx) => {
            return (
              <Grid item xs={12} md={4} key={`history-${idx}`}>
                <HistoryCard data={data} click={() => clickHandler(data)} />
              </Grid>
            );
          })}
        <Grid item xs={12} sx={{ display: { md: 'none' } }}>
          <Button
            variant='contained'
            size='large'
            onClick={toggleDrawer(false)}
            sx={{
              width: '100%',
              background: theme.palette.common.white,
              color: theme.palette.common.white.contrastText,
            }}
          >
            ❌
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default HistoryDrawer;
