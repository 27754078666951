import * as React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

export default function Usage({ data, developer }) {
  if (!data) {
    data = {};
  }
  const gpt4Allow = data.gpt4;
  const infinite = data.infinite;
  // if (!data.totalUsage) {
  //   data.totalUsage = {};
  //   data.totalUsage.gpt4 = 0;
  //   data.totalUsage.chatGPT = 0;
  // }
  // if (!data.allow) {
  //   data.allow = {};
  //   data.allow.gpt4 = 0;
  //   data.allow.chatGPT = 0;
  // }
  // let gpt4Usage = Math.round((data.totalUsage.gpt4 / data.allow.gpt4) * 100);
  // const gpt4Allow = data.allow.gpt4 - data.totalUsage.gpt4;
  // let chatgptUsage = Math.round(
  //   (data.totalUsage.chatGPT / data.allow.chatGPT) * 100
  // );
  // const chatgptAllow = data.allow.chatGPT - data.totalUsage.chatGPT;

  // gpt4Usage = isNaN(gpt4Usage) ? 0 : 100 - gpt4Usage;
  // chatgptUsage = isNaN(chatgptUsage) ? 0 : 100 - chatgptUsage;

  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            variant="body1"
            color="text.secondary"
            noWrap={true}
            sx={{ pr: 1 }}
          >
            AI使用次數:
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {/* <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={gpt4Usage}
            color="secondary"
            sx={{ height: 15, borderRadius: 5, backgroundColor: grey[200] }}
          />
        </Box> */}
        <Box>
          {developer || infinite ? (
            <Typography variant="body1" color="text.secondary" noWrap={true}>
              無限
            </Typography>
          ) : (
            <Typography variant="body1" color="text.secondary" noWrap={true}>
              剩餘{gpt4Allow}次
            </Typography>
          )}
        </Box>
      </Box>
      {/* {developer && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <Typography
              variant="body1"
              color="text.secondary"
              noWrap={true}
              sx={{ pr: 1 }}
            >
              GPT-3.5普通
            </Typography>
          </Box>
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress
              variant="determinate"
              value={chatgptUsage}
              color="primary"
              sx={{ height: 15, borderRadius: 5, backgroundColor: grey[200] }}
            />
          </Box>
          <Box>
            <Typography variant="body1" color="text.secondary" noWrap={true}>
              剩餘{chatgptAllow}次
            </Typography>
          </Box>
        </Box>
      )} */}
    </Box>
  );
}
